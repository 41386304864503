import { Building, FolderLock, Key, Lock, Shield, Waves } from 'lucide-react';
import React from 'react';

export default function SecurityComponent({
  className
}: {
  className?: string;
}) {
  return (
    <section
      id="security"
      className={`w-full sm:px-10 py-10 sm:my-10 z-100 text-gray-50  lg:px-0 tails-selected-element ${className}`}
    >
      <div className="max-w-4xl pt-4 sm:pt-0  md:py-8 mx-auto ">
        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
          <div>
            <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
              From SSO to BYOK
            </div>
          </div>
          <h2 className="h2 z-[10000000000] bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
            Enterprise-grade security
          </h2>
        </div>
      </div>
      <div className=" divide-y divide-gray-800 max-w-6xl mx-auto px-4 sm:px-6">
        {/* Row */}
        <div className="py-4 first-of-type:pt-0 last-of-type:pb-0">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 md:gap-12 mb-2 ">
          <div>
              <div className="p-2 flex items-center justify-center w-fit border border-gray-700 rounded-lg bg-gray-800/75">
                <Shield size={20} />
              </div>
              <div className="flex items-center space-x-2 mb-1 mt-4">
                <h4 className="font-medium text-gray-50">
                  SOC 2 Type II
                </h4>
              </div>
              <p className="text-xs sm:text-sm text-gray-400">
                Mendable is SOC 2 Type II certified. Check out our <a href='https://mendable.wolfia.com/?ref=mendable-website' target='_blank' rel='noopener noreferrer' className='text-gray-400 underline'>AI Trust Center</a> for additional information.
              </p>
            </div>
            <div>
              <div className="p-2 flex items-center justify-center w-fit border border-gray-700 rounded-lg bg-gray-800/75">
                <Lock size={20} />
              </div>
              
              <div className="flex items-center space-x-2 mb-1 mt-4">
                <h4 className="font-medium text-gray-50">
                  SSO (SAML, OIDC, OAuth)
                </h4>
              </div>
              <p className="text-xs sm:text-sm text-gray-400">
               Supports SAML 2.0, OpenID Connect, and OAuth 2.0 for
                single sign-on (SSO) and identity federation.
              </p>
            </div>
            <div>
              <div className="p-2 flex items-center justify-center w-fit border border-gray-700 rounded-lg bg-gray-800/75">
                <Building size={20} />
              </div>
              <div className="flex items-center space-x-2 mb-1 mt-4">
                <h4 className="font-medium text-gray-50">
                  RBAC (Project and chunk level)
                </h4>
              </div>
              <p className="text-xs sm:text-sm text-gray-400">
                Role-based access control to ensure that only the
                right people have access to the right data.
              </p>
            </div>
            
            <div>
              <div className="p-2 flex items-center justify-center w-fit border border-gray-700 rounded-lg bg-gray-800/75">
                <FolderLock size={20} />
              </div>
              <div className="flex items-center space-x-2 mb-1 mt-4">
                <h4 className="font-medium text-gray-50">
                  Secure Data Connectors
                </h4>
              </div>
              <p className="text-xs sm:text-sm text-gray-400">
                Integrate securely to Google Drive, Salesforce, Zendesk and more using OAuth 2.0.
              </p>
            </div>
            <div>
              <div className="p-2 flex items-center justify-center w-fit border border-gray-700 rounded-lg bg-gray-800/75">
                <Key size={20} />
              </div>
              <div className="flex items-center space-x-2 mb-1 mt-4">
                <h4 className="font-medium text-gray-50">
                  BYOK / BYOM
                </h4>
              </div>
              <p className="text-xs sm:text-sm text-gray-400">
                Bring your own key or custom model to Mendable to ensure compliance.
              </p>
            </div>
            <div>
              <div className="p-2 flex items-center justify-center w-fit border border-gray-700 rounded-lg bg-gray-800/75">
                <Waves size={20} />
              </div>
              <div className="flex items-center space-x-2 mb-1 mt-4">
                <h4 className="font-medium text-gray-50">
                  Rate Limiting
                </h4>
              </div>
              <p className="text-xs sm:text-sm text-gray-400">
                Project and user rate limit protection to prevent abuse and ensure availability.
              </p>
            </div>
          </div>
          
        </div>

      
        
        
      </div>
    </section>
  );
}
