import { Button } from '@/components/app/ui/button';
import router from 'next/router';
import React, { useEffect, useRef } from 'react';
import { CgArrowUpR } from 'react-icons/cg';
import { FaReact } from 'react-icons/fa';
import createGlobe from 'cobe';
import CodeDisplay, { CodeDisplaySimple } from '../../CodeDisplay';
import Highlighter, { HighlighterItem } from '@/components/v2/Highlighter';

const defaultCode = `
import { MendableSearch } from '@mendable/search'

<MendableSearchBar anon_key={MENDABLE_ANON_KEY} />
`;

const code2 = `
curl -X POST https://api.mendable.ai/v0/mendableChat
  -H "Content-Type: application/json"
  -d '{
        "api_key": "YOUR_API_KEY",
        "question": "How do I create a new project?",
        "history": [],
        "conversation_id": 12345,
      }'
`;

interface Props {
  title: string;
  description?: string | React.ReactNode;
  url: string;
  IconComponent?: typeof FaReact;
  blurColor?: string;
}

export default function CoolCard({
  title,
  description,
  IconComponent = FaReact,
  url,
  blurColor = '#8559F4'
}: Props) {
  const canvasRef = useRef();

  useEffect(() => {
    let phi = 0;
    // @ts-ignore
    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 600 * 2,
      height: 600 * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.0, 0.0, 0],
      glowColor: [0.52, 0.34, 0.95],
      markers: [
        // longitude latitude
        { location: [37.7595, -122.4367], size: 0.03 },
        { location: [40.7128, -74.006], size: 0.1 }
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi;
        phi += 0.001;
      }
    });

    return () => {
      globe.destroy();
    };
  }, []);
  return (
    <Highlighter className="grwdoup">
      <HighlighterItem>
        <div className="relative h-full bg-gray-950 rounded-[inherit] z-20 overflow-hidden ">
          <div className="relative w-full h-full flex flex-col md:flex-row md:items-center md:justify-between">
            {/* Blurred shape */}
            {/* <div
              className="  absolute flex items-center justify-center bottom-0 translate-y-3/4 left-1/2 -translate-x-1/2 pointer-events-none -z-10 h-full aspect-square"
              aria-hidden="true"
            >
              <div
                style={{
                  backgroundColor: blurColor
                }}
                className="absolute inset-0 translate-z-0  rounded-full blur-[50px] opacity-70"
              />
              <div className="absolute w-1/4 h-1/4 translate-z-0 bg-primary/25 rounded-full blur-[40px]" />
            </div> */}

            
            <div className=" w-full flex flex-col items-start pt-4 pb-4 pl-6   pr-4 rounded-lg  transition-all  ">
            <div className="ml-1 sm:ml-4  my-8 sm:my-14 max-w-3xl w-full text-start">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-1">
                From zero to production in minutes
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Deploy anywhere
            </h2>
            <p className="text-base max-w-[300px] sm:text-lg text-gray-400 sm:max-w-xl self-start">
Deploy Mendable internally, externally, or both. Our API allows you to send and query data from anywhere.
              
            </p>
            <Button onClick={()=> {
              // navigate to docs
              window.open('https://docs.mendable.ai', '_blank')
            }} className="mt-6 btn w-fit text-lg text-white bg-gray-900 border border-gray-800 0 hover:bg-opacity-75  transition duration-150 ease-in-out group" >
                  View Documentation 
                  {/* <div className='ml-1 bg-white bg-opacity-25 text-[8px] leading-3 px-[2px] py-[2px]  h-fit rounded-md'>
                    ALPHA
                  </div> */}
                </Button>
          </div>
              <canvas
                className="absolute -right-48 sm:right-0"
                //@ts-ignore
                ref={canvasRef}
                style={{
                  width: 410,
                  height: 600,
                  maxWidth: '100%',
                  aspectRatio: 1
                }}
              />
              
              
              
            </div>
            
          </div>
        </div>
      </HighlighterItem>
    </Highlighter>
  );
}
