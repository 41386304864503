import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import CodeDisplay, { CodeDisplaySimple } from '../../CodeDisplay';
import { HeroButton } from '../../Button/HeroButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import UsageExample from '@/components/v2/UsageExample';
import { MdCheck, MdNumbers } from 'react-icons/md';

const code2 = `
curl -X POST https://api.mendable.ai/v0/mendableChat
  -H "Content-Type: application/json"
  -d '{
        "api_key": "YOUR_API_KEY",
        "question": "How do I create a new project?",
        "history": [],
        "conversation_id": 12345,
      }'
`;
function Features02() {
  return (
    <section className="relative">
      {/* Bg gradient */}
      <div
        className="absolute top-0 left-0 right-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Bg illustration */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                Explore your dashboard
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Get insights from usage
            </h2>
            {/* <p className="text-lg text-gray-400 max-w-xl self-center mx-auto">
              From small startups to Fortune 500 companies, we're proud to be
              the cutting edge platform for any 'chat with data' applications
            </p> */}
          </div>
          {/* Section content */}
          <div className="max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-18 space-y-8 space-y-reverse md:space-y-0">
            
            {/* Image */}
             <div className="md:w-5/12 lg:w-1/2 scale-90 sm:scale-10" data-aos="zoom-out">
            <UsageExample />
                {/* <LazyLoadImage
                    effect="blur" // add the effect you want
                    src="/insights2.gif" // use normal <img> attributes as props
                    wrapperClassName="" // put here your classes
                /> */}
                </div>
            {/* Content */}
            <div
              className="md:w-7/12 lg:w-1/2 order-1 md:order-none flex flex-col"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <div className="text-center md:text-left">
                <h3 className="text-3xl mb-4">
                  Understand all interactions
                </h3>
                <p className="text-lg text-gray-400 mb-6">
                  Unravel your users' queries, track
                  their interactions, customize responses, and monitor your
                  product usage effortlessly.
                </p>
                <ul className="inline-flex flex-row flex-wrap items-center  justify-center sm:justify-start sm:items-start sm:flex-col text-lg text-gray-400 space-x-4 sm:space-x-0 sm:space-y-3">
                  <li className="flex items-center">
                    <span className='hidden sm:flex mx-2'>-</span>
                    <span>Gain key insights into user queries</span>
                  </li>
                  <li className="flex items-center">
                  <span className='hidden sm:flex mx-2'>-</span>

                    
                    <span>Monitor real-time product-user interactions</span>
                  </li>
                  <li className="flex items-center">
                  <span className='hidden sm:flex mx-2'>-</span>

                    
                    <span>Fine-tune your model for optimized responses</span>
                  </li>
                  <li className="flex items-center">
                  <span className='hidden sm:flex mx-2'>-</span>

                    
                    <span>Track and evaluate Mendable usage</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 px-10 max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-18 space-y-8 space-y-reverse md:space-y-0">
            
            {/* Image */}
             
            {/* Content */}
            <div
              className="md:w-7/12 lg:w-1/2 order-1 md:order-none flex flex-col"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <div className="text-center md:text-left">
                <h3 className="text-3xl mb-4">
                  Insights beyond conversations
                </h3>
                <p className="text-lg text-gray-400 mb-6">
                  Learn what your users are asking, how they are asking, and their satisfaction level with the answers.
                  Teach the model based on the answers rating and improve the model's performance.
                </p>
                
              </div>
            </div>
            <div className="w-full md:w-7/12 mx-10  mx-auto" data-aos="zoom-out">
                <LazyLoadImage
                    effect="blur" // add the effect you want
                    src="/insights2.gif" // use normal <img> attributes as props
                    wrapperClassName="" // put here your classes
                />
                </div>
          </div>
        </div>
      </div>
    </section>
    
  );
}

export default Features02;
