import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { Fragment } from 'react';
import clsx from 'clsx'
import CodeDisplay, { CodeDisplaySimple } from '../../CodeDisplay';
import { HeroButton } from '../../Button/HeroButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import { SourceIcon } from '@/components/v2/DataIngestion';
import { Globe, Upload } from 'lucide-react';

const code2 = 
`
curl -X POST https://api.mendable.ai/v0/mendableChat
  -H "Content-Type: application/json"
  -d '{
        "api_key": "YOUR_API_KEY",
        "question": "How do I create a new project?",
        "history": [],
        "conversation_id": 12345,
      }'
`;
function Features02() {
  return (
    <section id="workflow" className="relative pt-10">
      {/* Bg gradient */}
      {/* Bg illustration */}
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl  text-start pb-4">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                Over 20+ data connectors
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-100 via-gray-200 to-gray-200/60 pb-4">
              Start by connecting your data
            </h2>
            <p className="text-lg text-gray-400  self-center mx-auto">
            Mendable offers managed ingestion through a simple online GUI and through our API. You can easily add, modify, or delete different types of sources.
            </p>
          </div>
          {/* Section content */}
            
            {/* Image */}
            <div className=" relative z-0 grid w-4/5 sm:w-fit mt-4 grid-cols-4  sm:grid-cols-8 self-center items-center justify-center gap-4">
              <SourceIcon id="github-mark" />

              <SourceIcon id="medium" />
              <SourceIcon id="gitbook" />
              <SourceIcon id="docusaurus" />
              <SourceIcon id="readme" />
              <SourceIcon id="notion" />
              <SourceIcon id="upload" />

              <SourceIcon id="confluence" />

              <SourceIcon id="drive" />
              <SourceIcon id="youtube" />
              <SourceIcon id="pdf" />
              <SourceIcon id="wordpress" />

              <SourceIcon id="slack" />
              <SourceIcon id="readme" />

              <SourceIcon id="website" />
              <SourceIcon id="zendesk" />
              
            </div>
          </div>
      </div>
    </section>
  );
}

export default Features02;
