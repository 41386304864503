import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import CodeDisplay, { CodeDisplaySimple } from '../../CodeDisplay';
import { HeroButton } from '../../Button/HeroButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FeaturesList from '@/components/v2/FeaturesList';

const code2 = `
curl -X POST https://api.mendable.ai/v0/mendableChat
  -H "Content-Type: application/json"
  -d '{
        "api_key": "YOUR_API_KEY",
        "question": "How do I create a new project?",
        "history": [],
        "conversation_id": 12345,
      }'
`;
function Features02() {
  return (
    <section className="relative">
      {/* Bg gradient */}
      <div
        className="absolute top-0 left-0 right-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Bg illustration */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-5xl mx-auto  text-center pb-4 md:pb-12">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                Easily Teach Your Model
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Customize your model
            </h2>
            <p className="text-lg text-gray-400  self-center max-w-xl  ml-auto">
              {/* We know every uses case is slightly different so the Mendable platform allows you to customize your model to fit your company's needs through multiple features. */}
              {/* Mendable offers managed ingestion through a simple online GUI and through our API. You can easily add, modify, or delete different types of sources. */}
            </p>
          </div>
          <div className="sm:w-2/3 mx-auto mb-8">
          {/* <section className="hidden sm:flex relative overflow-hidden mb-10 ">
                <div
                  style={{ padding: '1.5px' }}
                  className={`loading-box2 overflow-hidden mx-auto w-fit  relative flex flex-col items-center justify-center rounded-2xl`}
                >
                  <span className="spananim"></span>
                  <div className="relative h-fit z-10 w-fit rounded-2xl "> */}
                    <LazyLoadImage
                      effect="blur" // add the effect you want
                      src="/Slide 16_9 - 7 (9).png" // use normal <img> attributes as props
                      wrapperClassName="" // put here your classes
                    />
                  {/* </div>
                </div>
              </section>
          </div> */}
          </div>

          <FeaturesList />

          <div className="hidden max-w-xl mx-auto md:max-w-none flex-col md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-18 space-y-8 space-y-reverse md:space-y-0">
            <div
              className="flex w-full items-center justify-end self-end md:w-5/12 lg:w-1/2 s"
              data-aos="zoom-out"
            >
              <section className="hidden sm:flex relative overflow-hidden mb-10 ">
                <div
                  style={{ padding: '1.5px' }}
                  className={`loading-box2 overflow-hidden mx-auto w-fit  relative flex flex-col items-center justify-center rounded-2xl`}
                >
                  <span className="spananim"></span>
                  <div className="relative h-full z-10 min-w-[800px] max-w-[800px]   ">
                    <LazyLoadImage
                      effect="blur" // add the effect you want
                      src="/customizemodel3.gif" // use normal <img> attributes as props
                      wrapperClassName="" // put here your classes
                    />
                  </div>
                </div>
              </section>
            </div>
            <div
              className="md:w-7/12 lg:w-1/2 order-1 md:order-none flex flex-col"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <div className="text-center md:text-left">
                <h3 className="h3 font-uncut-sans text-4xl mb-4">
                  Make it perfect for your use case
                </h3>
                <p className="text-xl text-gray-400 mb-6">
                  We know every uses case is slightly different so the Mendable
                  platform allows you to customize your model to fit your
                  company's needs through multiple features.
                </p>
                <ul className="inline-flex flex-row flex-wrap items-center  justify-center sm:justify-start sm:items-start sm:flex-col text-lg text-gray-400 space-x-4 sm:space-x-0 sm:space-y-3">
                  <li className="flex items-center">
                    <svg
                      className="w-3 h-3 fill-current text-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>
                      Support for multiple base LLM models (including privacy
                      first models)
                    </span>
                  </li>

                  <li className="flex items-center">
                    <svg
                      className="w-3 h-3 fill-current text-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Training through answer correction</span>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3 h-3 fill-current text-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Custom prompt edits</span>
                  </li>
                  <li className="flex items-center">
                    <svg
                      className="w-3 h-3 fill-current text-emerald-500 mr-3 shrink-0"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Model creativity control</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features02;
