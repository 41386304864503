import { calLink } from '@/utils/constants';
import React from 'react';
import { Button } from '@/components/app/ui/button';
import { Menu } from '@headlessui/react';
import Link from 'next/link';
import { useEffect } from 'react';

function Hero() {
  return (
    <section className="pt-10 relative overflow-hidden">
      {/* Bg gradient */}
      <div
        className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Illustration */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-36 md:pb-20">
          {/* Hero content */}
          <div className=" flex flex-col items-center justify-center mx-auto  md:mx-0 text-center md:text-center">
            <div>
              {/* <Button className="rounded-xl !text-sm  font-mono bg-mendable-600 text-mendable-100 hover:bg-opacity-75 border border-primary w-full transition duration-150 ease-in-out group">
                    npm i @mendable/search
                  </Button> */}
              {/* <div className="relative text-sm text-gray-300  animate-rotate-hover bg-gray-800 rounded-full inline-block px-4 py-2 mb-6 before:content-[''] before:absolute before:-z-10 before:inset-0 before:-m-0.5 before:bg-gradient-to-t before:from-gray-800 before:to-gray-800 before:via-gray-600 before:rounded-full">
                <div className="text-gray-400">
                Launching our API{' '}
                  <a
                    className="ml-2 font-medium text-primary inline-flex items-center transition duration-150 ease-in-out group"
                    href="https://docs.mendable.ai/"
                  >
                    Learn More{' '}
                    <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                      -&gt;
                    </span>
                  </a>
                </div>
              </div> */}
              <a
                className="mb-2 md:mb-4  group-hover:-translate-y-0.5 btn-sm py-0.5 bg-gray-800/50 !border !border-primary/10 text-gray-100 hover:text-gray-300 transition delay-100 duration-500    xl:animate-fade-in-down    relative "
                href="https://docs.mendable.ai"
              >
                <span className="relative inline-flex items-center font-mono text-xs ">
                  <span className=" text-gray-500  transition-transform duration-150 ease-in-out mr-1">
                    $
                  </span>{' '}
                  npm i @mendable/search
                </span>
              </a>
              {/* <div className="w-fit mx-auto mb-8">
                <CodeDisplaySimple code={`npm i @mendable/search`} />
                </div> */}
            </div>
            {/* <h1 className="sr-only">
              Mendable - AI that understands and answers technical questions
            </h1> */}
            <div className="overflow-visible h-fit">
              <h1 className="font-bold text-[36px] sm:text-[48px] md:text-[68px] leading-[40px] sm:leading-[54px] md:leading-[78px] overflow-visible font-uncut-sans sm:mb-2 mt-4 bg-clip-text text-transparent bg-gradient-to-r from-gray-100/75 via-white to-gray-200/75 transition xl:animate-fade-in-down duration-500 delay-100">
                Just in time answers
                <br />
                for Sales and Support
              </h1>
            </div>
            {/* <h2
              className="h1 !text-[68px] font-uncut-sans  mb-4 md:mb-6 bg-clip-text text-transparent  bg-gradient-to-r from-gray-100/75 via-white to-gray-200/75"
              data-aos="zoom-out"
              data-aos-delay="100"
            >
             {/* your technical product */}
            {/* questions with AI */}
            {/* </h2> */}

            <p className="mt-4 text-md sm:text-lg max-w-xs sm:max-w-md md:max-w-xl xl:max-w-2xl md:text-lg xl:text-xl text-gray-200 mb-8 transition xl:animate-fade-in-down duration-500 delay-700 leading-[18px] sm:leading-[24px] md:leading-[32px]">
              {/* Answer customer and employee questions with a chatbot that's trained on your technical resources */}
              Train a secure AI on your technical resources that answers
              customer and employee questions so your team doesn't have to
            </p>
            <div className="max-w-xs mx-auto sm:max-w-none flex items-center justify-center  space-y-0 space-x-3 transition xl:animate-fade-in-down duration-500 delay-1000">
              {/* <HeroButton
                variant="rectangle"
                color="purple"
                className="mr-2"
                // href="https://fk4bvu0n5qp.typeform.com/to/vvGH3XMG"
                onClick={()=> signInWithGoogle()}
              >
                <span className="">Get Started</span>
              </HeroButton> */}
              <div>
                <Link href="/signup">
                  <Button className="btn !text-[17px] text-white bg-mendable-500 0 hover:bg-opacity-75 w-full ">
                    Get Started
                    {/* <svg className='ml-1' width="18" height="18" viewBox="0 0 16 16" fill="#ffffff"><path d="M5.46967 11.4697C5.17678 11.7626 5.17678 12.2374 5.46967 12.5303C5.76256 12.8232 6.23744 12.8232 6.53033 12.5303L10.5303 8.53033C10.8207 8.23999 10.8236 7.77014 10.5368 7.47624L6.63419 3.47624C6.34492 3.17976 5.87009 3.17391 5.57361 3.46318C5.27713 3.75244 5.27128 4.22728 5.56054 4.52376L8.94583 7.99351L5.46967 11.4697Z"></path></svg> */}
                    {/* <div className='ml-1 bg-white bg-opacity-25 text-[8px] leading-3 px-[2px] py-[2px]  h-fit rounded-md'>
                    ALPHA
                  </div> */}
                  </Button>
                </Link>
              </div>
              {/* <HeroButton href={calLink} variant="rectoutline" color="white">
                <span className="">Talk to us</span>
              </HeroButton> */}
            </div>
            <div className="mt-8 flex flex-row items-center justify-center lg:justify-start transition xl:animate-fade-in-down duration-500 delay-500">
              <div className="flex items-center text-gray-50 transform   font-medium uppercase  text-xs sm:px-0 px-5 text-center sm:text-xs rounded-xl leading-tight">
                <span className="">Backed BY</span>
              </div>
              <div className="-ml-2 sm:ml-3 flex items-center justify-start">
                <img src="/yc.svg" className="h-6" alt="Y Combinator Logo" />
                <span className="ml-[6px] bg-gradient-to-br from-white to-mendable-300 bg-clip-text text-transparent sm:inline-block text-sm font-semibold">
                  Combinator
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
