import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Button } from '@/components/app/ui/button';
import CoolCard from '../ui/v1/Cards/CoolCard';
import { ChatBubbleBottomCenterIcon, CodeBracketIcon } from '@heroicons/react/24/outline';
import { Search, SearchIcon } from 'lucide-react';
import { BsDisplay } from 'react-icons/bs';
import { BiMobile } from 'react-icons/bi';
import CodeDisplay, { CodeDisplaySimple } from '../ui/CodeDisplay';

const defaultCode = `
import { MendableSearch } from '@mendable/search'

<MendableSearchBar anon_key={MENDABLE_ANON_KEY} />
`;

const code2 = `
curl -X POST https://api.mendable.ai/v0/mendableChat
  -H "Content-Type: application/json"
  -d '{
        "api_key": "YOUR_API_KEY",
        "question": "How do I create a new project?",
        "history": [],
        "conversation_id": 12345,
      }'
`;

const SourceIcon = ({
  Icon,
  id,
  onClick,
  selected
}: {
  Icon?: any;
  id?: string;
  onClick: any;
  selected: boolean;
}) => {
  return (
    <div className="relative" onClick={onClick}>
      <div
        className={`z-20 ${
          selected ? 'border-gray-500' : 'border-gray-800'
        }  flex items-center justify-center aspect-square rounded-full border  hover:border-gray-500  p-3 text-white hover:animate-rotate-hover hover:cursor-pointer transition-all`}
      >
        {Icon && <Icon className="h-8 w-8 sm:h-10 sm:w-10 aspect-square" />}
      </div>
    </div>
  );
};
export default function ChooseYourComponent() {
  const componentTypes : any = {
      search: {
        name: 'Search',
        code: `
import { MendableSearchBar } from '@mendable/search'

<MendableSearchBar anon_key={MENDABLE_ANON_KEY} />
`,
        description: 'A search bar that integrates with your Mendable account.'
      },
      chat: {
        name: 'Chat',
        code: `
import { MendableFloatingButton } from '@mendable/search'

<MendableFloatingButton anon_key={MENDABLE_ANON_KEY} />
`,
        description: 'A chat bubble that integrates with your Mendable account.'
      },
      cli: {
        name: 'CLI',
        code: `
import { MendableInPlace } from '@mendable/search'

<MendableInPlace anon_key={MENDABLE_ANON_KEY} />
`,
        description: 'A CLI that integrates with your Mendable account.'
      },
      api: {
        name: 'API',
        code: `
curl -X POST https://api.mendable.ai/v0/mendableChat
-H "Content-Type: application/json"
-d '{
      "api_key": "YOUR_API_KEY",
      "question": "How do I create a new project?",
      "history": [],
      "conversation_id": 12345,
    }'
          `,
        description: 'A REST API that integrates with your Mendable account.'
      }
      } 

  const [componentType, setComponentType] = React.useState('search');

  return (
    <section className="relative">
      {/* Bg gradient */}
      <div
        className="absolute top-0 left-0 right-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Bg illustration */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-5xl  mx-auto text-center pb-8 md:pb-12">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                React, Vanilla JS, API
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Choose your component
            </h2>
            <p className="text-lg text-gray-400 max-w-xl self-center mx-auto">
              Mendable provides a variety of components ranging from
              search bars, to chat bubbles, to full CLIs built on our API. Customize them or easily build your own
            </p>
          </div>
          {/* Section content */}
          <div className="max-w-6xl mx-auto relative ">
            <div className="max-w-3xl mx-auto w-full ">
            
              <div className="flex items-center justify-center gap-4">
                
                <SourceIcon
                  Icon={ChatBubbleBottomCenterIcon}
                  selected={componentType === 'chat'}
                  onClick={() => setComponentType('chat')}
                />
                <SourceIcon
                  Icon={SearchIcon}
                  selected={componentType === 'search'}
                  onClick={() => setComponentType('search')}
                />
                <SourceIcon
                  Icon={BsDisplay}
                  selected={componentType === 'cli'}
                  onClick={() => setComponentType('cli')}
                />
                <SourceIcon
                  Icon={CodeBracketIcon}
                  selected={componentType === 'api'}
                  onClick={() => setComponentType('api')}
                />
              </div>
              <div className='relative'>
              <img src="/Frame 597 (3).png" alt="Mendable component" />
              <div className="absolute w-full h-64 bottom-0 bg-gradient-to-b  from-transparent via-transparent to-black" />


              </div>
              
              <div className="hidden sm:flex absolute -bottom-8 -right-0">
                <CodeDisplaySimple code={componentTypes[componentType].code} />
              </div>
            </div>

            {/* <div
                className="max-w-3xl flex items-center justify-between gap-4 mt-4"
                data-aos="zoom-out"
              >
                <div className="">
                <CodeDisplaySimple code={defaultCode} />
                </div>

                <div className="">
                  <CodeDisplaySimple code={code2} />
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
