import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import MyModal from '../Dialog/Dialog';
import MuxPlayer from '@mux/mux-player-react';

import ShowcaseCard from '../v1/Cards/ShowcaseCard';
import UseCaseCard from '../v1/Cards/UseCaseCard';
import { FaChalkboardTeacher, FaReact } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BiSupport } from 'react-icons/bi';
import { AiOutlineRobot } from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import CoolCard from '../v1/Cards/CoolCard';
import createGlobe from 'cobe';
import Highlighter from '@/components/v2/Highlighter';

function restartVideo() {
  const video = document.querySelector('video');
  if (!video) return;
  video.currentTime = 0;
  video.play();
}

export default function Features2() {
  const [width, setWidth] = useState(0);
  // Use the useEffect hook to track the width of the element and update the state variable
  useEffect(() => {
    // Query the element and store it in a constant
    const element = document.querySelector('video');
    const outerElement = document.getElementById('outerElement');

    if (element === null) return;

    // Update the state variable with the element's width
    setWidth(element.clientWidth);

    // Set up an interval to track the width of the element continuously
    const interval = setInterval(() => {
      setWidth(element.clientWidth);
      if (!outerElement) return;

      outerElement.style.height = `${0.59 * element.clientWidth}px`;
    }, 500);

    // Return a cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const useCaseCards = [
    {
      title: 'Docs & Knowledge Base',
      url: '/usecases/documentation',
      description:
        'Decrease tickets & activation times with an AI assistant',
      IconComponent: IoDocumentTextOutline,
      blurColor: '#F9F9F9'
    },
    {
      title: 'Customer Success Enablement',
      url: '/usecases/cs-enablement',
      description: 'Use a technical AI copilot to increase retention',
      IconComponent: BiSupport,
      blurColor: '#8559f4'
    },
    {
      title: 'Product Copilot',
      url: '/usecases/productcopilot',
      description:
        'Speed up adoption with a technical assistant in your app',
      IconComponent: AiOutlineRobot
    },
    {
      title: 'Sales Enablement',
      url: '/usecases/sales-enablement',
      description: 'Use a technical AI copilot to build trust with prospects',
      IconComponent: FaChalkboardTeacher
    }
  ];

  const [shuffledCards, setShuffledCards] = useState(useCaseCards);

  // useEffect(() => {
  //   const shuffle = (array: any[]) => {
  //     for (let i = array.length - 1; i > 0; i--) {
  //       const j = Math.floor(Math.random() * (i + 1));
  //       [array[i], array[j]] = [array[j], array[i]];
  //     }
  //     return array;
  //   };

  //   setShuffledCards(shuffle([...useCaseCards])); // use a copy of useCaseCards to prevent modifying the original array
  // }, []);

  return (
    <>
      {/* <svg className="fill-black text-gray-100" viewBox="0 0 900 45" xmlns="http://www.w3.org/2000/svg">
                <path d="m0 4 50 3c50 3 150 9 250 13.7 100 4.6 200 8 300 4.3S800 10.7 850 5.3L900 0v45H0V4Z" fillRule="nonzero" className=""></path>
            </svg> */}
      <section className="z-[100000000000] flex md:hidden w-full px-10 pt-10 text-gray-50  lg:px-0 tails-selected-element">
        <div className="w-full sm:max-w-4xl pt-10 sm:pt-0  md:py-20 mx-auto b">
          <MuxPlayer
            className="rounded-xl box-shadow"
            thumbnailTime={8}
            playbackId={'JUxSWHgHUFLusrkp9W01pcurSzT6lhyTr011o5xO6TRrQ'}
          />
        </div>
      </section>
      <section
        id="usecases"
        className="w-full px-10 py-10 sm:mt-10 z-100 text-gray-50  lg:px-0 tails-selected-element"
      >
        <div className="max-w-4xl pt-10 sm:pt-0  md:py-20 mx-auto ">
          <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                Deploy a knowledgable technical AI anywhere
              </div>
            </div>
            <h2 className="h2 z-[10000000000] bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Use Mendable for
            </h2>
            {/* <p className="text-lg text-gray-400 max-w-xl self-center mx-auto">
              From small startups to Fortune 500 companies, we're proud to be the cutting edge platform for any 'chat with data' applications
            </p> */}
          </div>

          <div className="mt-4 mb-2 grid grid-cols-1 gap-x-5 gap-y-6 sm:grid-cols-2 lg:grid-cols-2 grid-rows-1">
            {/* {shuffledCards.map((card) => (
              <UseCaseCard
                key={card.title}
                title={card.title}
                url={card.url}
                image={card.image}
                description={card.description}
                IconComponent={card.IconComponent}
              />
            ))} */}
            <UseCaseCard
              className=""
              key={shuffledCards[0].title}
              title={shuffledCards[0].title}
              url={shuffledCards[0].url}
              description={shuffledCards[0].description}
              IconComponent={shuffledCards[0].IconComponent}
            />

            <UseCaseCard
              className=""
              key={shuffledCards[1].title}
              title={shuffledCards[1].title}
              url={shuffledCards[1].url}
              description={shuffledCards[1].description}
              IconComponent={shuffledCards[1].IconComponent}
            />
            <UseCaseCard
              className=""
              key={shuffledCards[3].title}
              title={shuffledCards[3].title}
              url={shuffledCards[3].url}
              description={shuffledCards[3].description}
              IconComponent={shuffledCards[3].IconComponent}
            />

            <UseCaseCard
              className=""
              key={shuffledCards[2].title}
              title={shuffledCards[2].title}
              url={shuffledCards[2].url}
              description={shuffledCards[2].description}
              IconComponent={shuffledCards[2].IconComponent}
            />
          </div>
          {/* <div className="mt-8">
            <CoolCard
              key={'awd'}
              title={'Deploy Mendable anywhere in minutes'}
              url={'card.url'}
              description={
                'With our production ready React components and easy to use API you can deploy Mendable your website, mobile app, or any other platform in minutes.'
              }
            />
          </div> */}

          {/*<h2 className="flex justify-center items-center text-center w-full  font-bold text-2xl  md:text-4xl md:text-center md:justify-center">
            <span className="text-3xl font-bold  sm:text-5xl">
              Upgrade your docs with AI
            </span>
          </h2>*/}

          {/* <p className="">Yes, these answers are really mendable</p> */}

          {/* Animate on appear */}
          {/*<motion.div className="relative w-full h-full flex flex-col items-center justify-center mt-14 ">
            <div className="relative w-full h-full   flex items-center justify-center  rounded-xl">*/}
          {/* <div className="relative w-full pb-[56.27198%]">
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  width="500"
                  height="294"
                  src="https://www.youtube.com/embed/SAHdqHvI_ts?&theme=dark&autohide=2&modestbranding=1&showinfo=0&rel=0"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div> */}

          {/* <img src="/chat.png" className="w-full h-full rounded-lg box-shadow border border-primary" /> */}
          {/* <div id="outerElement" className={` relative w-full h-[${(2 / 3) * width}px] mt-24`}>
                        <video style={{ boxSizing: "border-box" }} id="demo-video" className="absolute w-full rounded-lg overflow-hidden box-shadow" autoPlay loop muted playsInline>
                            <source src="/awesomesauce.mp4" type="video/mp4" />
                        </video>
                        <div className="flex transition opacity-0 hover:opacity-100 absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 rounded-lg">
                            
                            <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
                                <button onClick={restartVideo} className="w-16 h-16 bg-black border border-primary  rounded-full flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className='w-8 h-8 stroke-primary fill-primary' id="Layer_1"  viewBox="0 0 512 512"  data-name="Layer 1"><path d="m446.7 399.865a222.705 222.705 0 0 1 -136.115 104.447 225.453 225.453 0 0 1 -58.384 7.735 224.455 224.455 0 0 1 -216.168-166.247 24 24 0 0 1 46.367-12.425c25.142 93.831 121.929 149.715 215.765 124.573s149.713-121.934 124.57-215.764c-21.12-78.811-91.381-131.032-170.135-130.684l37.067 36.187a24 24 0 0 1 -33.53 34.347l-72.145-70.434a24 24 0 0 1 0-34.347l72.143-70.426a24 24 0 1 1 33.53 34.346l-23.358 22.8a224.22 224.22 0 0 1 180.393 335.892z"/></svg>
                                </button>
                            </div> 
                        </div>
                    </div> 
            </div>
          </motion.div>*/}
        </div>
      </section>
      <section
        id="features1"
        className="hidden w-full px-10 text-gray-50 bg-black lg:px-0 tails-selected-element"
      >
        <div className="max-w-4xl flex flex-col items-center justify-center sm:pt-0 mx-auto ">
          <h2 className="text-2xl sm:text-3xl  mb-8 self-start">
            See how companies implement Mendable
          </h2>
          {/* <canvas
                className="self-start w-full mx-auto"
                //@ts-ignore
                ref={canvasRef}
                style={{
                  width: 700,
                  height: 600,
                  maxWidth: '100%',
                  aspectRatio: 1
                }}
              /> */}
          <div className="mt-4 grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
            <ShowcaseCard
              title="Langchain Docs"
              url="https://python.langchain.com"
              description="One of the most popular frameworks for developing AI applications"
              image="/langchain.png"
            />
            <ShowcaseCard
              title="0x Docs"
              url="https://0x.org/docs"
              description="0x offers the core building blocks to create the most powerful Web3 apps"
              image="/0xlogo.png"
            />
            <ShowcaseCard
              title="Zenlytics"
              url="https://docs.zenlytic.com"
              description="Self-serve analytics tool that helps you answer the deeper questions you have about your data"
              image="/zenlytics.png"
            />
            {/* <ShowcaseCard
              title="FrontMatter Docs"
              url="https://frontmatter.codes/docs"
              description="A Headless CMS right in your code editor."
              image="/frontmatter.png"
            /> */}

            <ShowcaseCard
              title="Llama Index"
              url="http://gpt-index.readthedocs.io"
              description="A central interface to connect your LLM’s with external data."
              image="/LlamaIndex.png"
            />
            <ShowcaseCard
              title="Spectrocloud"
              url="https://docs.spectrocloud.com/"
              description="K8s management uniquely built for scale. Manage the lifecycle of any type of cluster."
              image="/spectrocloud-white.png"
            />
            <ShowcaseCard
              title="Code GPT"
              url="https://www.codegpt.co/"
              description="With over 450,000 installs, CodeGPT brings AI inside your code editor."
              image="/codegpt.png"
            />
            {/* <ShowcaseCard
              title="Devtools AI"
              url="https://www.codegpt.co/"
              description="AI inside your IDE with this open source extension"
              image="/codegpt.png"
            />
            <ShowcaseCard
              title="Ideal Postcodes"
              url="https://www.codegpt.co/"
              description="AI inside your IDE with this open source extension"
              image="/codegpt.png"
            /> */}
          </div>
          <p className="mt-20 text-gray-400 self-center">And many more...</p>
        </div>
      </section>
      {/* <section id="features2" className="w-full px-10 py-5 text-gray-50 bg-black lg:px-0 tails-selected-element" >
            <div className="max-w-4xl md:py-20 mx-auto">

                <h2 className="mb-4 flex justify-center items-center text-center w-full  font-bold text-2xl md:text-4xl md:text-center md:justify-center">
                    <span className="">Ingest any resources</span>
                </h2>
                <div className="flex justify-center gap-4 py-10">
                
                  <div className="md:w-20 md:h-20 md:mx-5 mx-1 w-12 h-12">
                    <img className="w-full h-full object-cover" src="/docs-logo.png" alt="Avatar"/>
                    <p className="invisible md:visible my-4 text-gray-100 flex items-center justify-center">Documentation</p>
                  </div>
                  <div className="md:w-20 md:h-20 md:mx-5 mx-1  w-12 h-12">
                    <img className="w-full h-full object-cover" src="/stackoverflow-logo.png" alt="Avatar"/>
                    <p className="invisible md:visible my-4 text-gray-100 flex items-center justify-center">StackOverflow</p>
                  </div>
                  <div className="md:w-20 md:h-20 md:mx-5 mx-1  w-12 h-12">
                    <img className="w-full h-full object-cover" src="/github-logo.png" alt="Avatar"/>
                    <p className="invisible md:visible my-4 text-gray-100 flex items-center justify-center">GitHub</p>
                  </div>
                  <div className="md:w-20 md:h-20 md:mx-5 mx-1 w-12 h-12">
                    <img className="w-full h-full object-cover" src="/slack-logo.png" alt="Avatar"/>
                    <p className="invisible md:visible my-4 text-gray-100 flex items-center justify-center">Slack</p>
                  </div>
                  <div className="md:w-20 md:h-20 md:mx-5 mx-1 w-12 h-12">
                    <img className="w-full h-full object-cover" src="/discord-logo.png" alt="Avatar"/>
                    <p className="invisible md:visible my-4 text-gray-100 flex items-center justify-center">Discord</p>
                  </div>
                  <div className="hidden sm:block md:w-20 md:h-20 w-12 h-12">
                    <div className="md:w-20 md:h-20 w-12 h-12 flex items-center justify-center">
                        <img className="h-3 md:h-7 object-cover" src="/add-logo.png" alt="Avatar"/>
                    </div>
                    <p className="invisible sm:visible my-4 text-gray-100 sm:flex items-center justify-center">Plus more</p>
                  </div>
                  
                </div>
            </div>
        </section> */}
    </>
  );
}
