import { Card, BarChart, Title, Text } from "@tremor/react";

const data = [
  {
    Month: "Jan 21",
    "GPT-3.5": 2890,
    "GPT-4": 2400,
  },
  {
    Month: "Feb 21",
    "GPT-3.5": 1890,
    "GPT-4": 1398,
  },
  // ...
  {
    Month: "Jan 22",
    "GPT-3.5": 3890,
    "GPT-4": 2980,
  },
];

export default function UsageExample() {
  return (
    <Card className="bg-transparent dark:bg-transparent rounded-xl">
      <Title>Usage</Title>
      <Text>Number of chat messages per month</Text>
      <BarChart
        className="mt-4 h-80 bg-transparent"
        data={data}
        showGridLines={false}
        showAnimation={true}
        index="Month"
        categories={["GPT-3.5", "GPT-4"]}
        colors={["indigo", "violet"]}
        stack={false}
        yAxisWidth={60}
        
      />
    </Card>
  );
}