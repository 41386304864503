import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import MyModal from '../Dialog/Dialog';
import MuxPlayer from '@mux/mux-player-react';

import ShowcaseCard from '../v1/Cards/ShowcaseCard';
import UseCaseCard from '../v1/Cards/UseCaseCard';
import { FaChalkboardTeacher, FaReact } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BiBuilding, BiSupport } from 'react-icons/bi';
import { AiOutlineDatabase, AiOutlineRobot, AiOutlineTeam } from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import CoolCard from '../v1/Cards/CoolCard';
import createGlobe from 'cobe';
import Highlighter from '@/components/v2/Highlighter';
import EnterpriseFeatureCard from '../v1/Cards/EnterpriseFeatureCard';



export default function EnterpriseReady() {
  
  return (
    <>
    
      <section
        id="usecases"
        className="w-full px-10 pb-20 sm:mt-10 z-100 text-gray-50  lg:px-0 tails-selected-element"
      >
        <div className="max-w-5xl pt-10 sm:pt-0  md:pt-20 mx-auto ">
          <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
               AI Chat Infrastructure built for production
              </div>
            </div>
            <h2 className="h2 z-[10000000000] bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Enterprise ready out of the box
            </h2>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-x-5 gap-y-6 sm:grid-cols-3 lg:grid-cols-3 grid-rows-1">  
              
              <EnterpriseFeatureCard
                className='col-span-1 row-span-1 w-full'
                key="a"
                title="Verified Sources"
                description="Reduce hallucinations by grounding answers with sources from your documentation"
                IconComponent={AiOutlineDatabase}
              />
              <EnterpriseFeatureCard
                className='col-span-1 row-span-1'
                key="b"
                title="Enterprise Grade Security"
                description="Our platform is built for enterprises in mind. We provide RBAC, bring your own model, and SLAs"
                IconComponent={BiBuilding}
              />
              <EnterpriseFeatureCard
                className='col-span-1 row-span-1'
                key="c"
                title="Ready for the whole team"
                description="Mendable supports single-sign-on so your entire team can train, manage your custom AI"
                IconComponent={AiOutlineTeam}
              />
          </div>
          
        </div>
      </section>
      
    </>
  );
}
