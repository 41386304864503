import { Product } from 'types';
import Hero from '@/components/ui/v1/Hero/Hero';
import CTASection from '@/components/ui/CTA/CTA1';
import Features2 from '@/components/ui/Features/Features2';
import Faq from '@/components/ui/HowItWorks/Faq';
import { MendableInPlace } from '@mendable/search';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import SenjaTweets from '@/components/app/SenjaTweets';
import Features6 from '@/components/ui/v1/Features/Features6';
import Features4 from '@/components/ui/v1/Features/Features4';
import Features5_new from '@/components/ui/v1/Features/Features5_new';
import Features7 from '@/components/ui/v1/Features/Features7';
import { mendableInitialConversation } from '@/lib/mendable_initial_conv';
import { CompaniesGrid } from '@/components/v2/CompaniesCarousel';
import ChooseYourComponent from '@/components/v2/ChooseYourComponent';
import CustomerTestimonials from '@/components/v4/CustomerTestimonials';
import React from 'react';
import EnterpriseReady from '@/components/ui/Features/EnterpriseReady';
import ContinuousTraining from '@/components/v2/ContinuousTraining';
import AnimatedLogoSVG from '@/components/brand/animated-logo-svg';
import ToolsHero from '@/components/tools/ToolsHero';
import SecurityComponent from '@/components/ui/Features/SecurityComponent';

interface Props {
  products: Product[];
}

export default function Index({ count }: { count: number }) {
  const [scrollToView, setScrollToView] = useState(false);

  const [displaySearchInDemoComponent, setDisplaySearchInDemoComponent] =
    useState(false);
  const router = useRouter();
  // detect if router query reload = true, refresh the page
  useEffect(() => {
    // make sure window is defined
    if (typeof window === 'undefined') return;

    // print the whole url window
    console.log('router', window.location.href);

    if (window.location.href.includes('access_token')) {
      // if query contains invite_token in the query param, push to router?invite_token=aowidjawiojdaw
      // get invite_token from localstorage
      const invite_token = localStorage.getItem('inviteToken');
      if (invite_token) {
        router.push(`/router?invite_token=${invite_token}`);
      } else {
        router.push('/router');
      }
    }
  }, [typeof window === 'undefined']);

  const saveChatHistoryToLocal = (chatHistory: any) => {
    const key = 'mendableBotChatHistorymendable';
    const chatHistoryJSON = JSON.stringify(chatHistory);
    localStorage.setItem(key, chatHistoryJSON);
  };
  const saveConversationId = (conversation: any) => {
    const key = 'mendableBotConversationIdmendable';
    const conversationJSON = JSON.stringify(conversation);
    localStorage.setItem(key, conversationJSON);
  };

  useEffect(() => {
    const key = 'mendableBotChatHistorymendable';
    saveChatHistoryToLocal(mendableInitialConversation);
    saveConversationId({ conversation_id: 1919191919 });
  }, []);

  // listen to a buton click that has Reset in the text

  // after the page is loaded, listen to the button click

  useEffect(() => {
    const btn = document.querySelector('.ms-reset-button');
    if (btn) {
      btn.addEventListener('click', () => {
        setScrollToView(false);
      });
    }
  }, [typeof window === 'undefined']);

  const [stars, setStars] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setIsLoaded(true);
  //   }, 1000); // Adjust the timeout to an approximate time it takes to load the component

  //   return () => clearTimeout(timeoutId);
  // }, []);

  useEffect(() => {
    const stars = Array.from({ length: 40 }).map((_, i) => {
      const left = Math.random() * window.innerWidth * 0.65; // Randomize left within the left third of the screen
      const top = Math.random() * window.innerHeight * 0.65; // Randomize top within the top third of the screen
      const animationDuration = Math.random() * 5 + 5; // Randomize animation duration between 0.5s and 1.5s
      const wh = Math.random() * 2 + 0.5;
      const starStyles: React.CSSProperties = {
        left: `${left}px`,
        top: `${top}px`,
        // randomize width and height, but maintain the aspect ratio
        width: wh,
        height: wh,
        opacity: Math.random() * 0.5 + 0.2, // Randomize opacity between 0.5 and 1
        animationDuration: `${animationDuration}s`,
        // blue star
        backgroundColor: `#D4F1F4`,
        boxShadow: `0 0 2px #D4F1F4, 0 0 5px #D4F1F4, 0 0 10px #D4F1F4, 0 0 15px #D4F1F4, 0 0 20px #D4F1F4, 0 0 25px #D4F1F4`
      };

      return <span key={i} className="blinking-star" style={starStyles}></span>;
    });
    setStars(stars);
  }, [typeof window === 'undefined']);

  // get screen size
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    // make sure window is defined
    if (typeof window === 'undefined') return;

    // save screen size to state
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    console.log(window.innerWidth);

    // listen to resize event and update screenSize state
    const handleResize = () => {
      console.log(window.innerWidth);

      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);

    // remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [typeof window === 'undefined']);

  function sendSlackMessage(message: string) {
    fetch('/api/slack/sendMendableMessage', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: message
      })
    });
  }

  return (
    <div className="relative overflow-hidden">
      {!scrollToView && (
        <section className="starssection">
          <span className="starsspan"></span>
          <span className="starsspan"></span>
          <span className="starsspan"></span>
          <span className="starsspan"></span>
          <span className="starsspan"></span>
          <span className="starsspan"></span>
        </section>
      )}
      {screenSize.width < 2000 && stars}
      {screenSize.width < 2000 && (
        <img
          src="/fullbgdsm.png"
          className="w-full h-[215vh] overflow-hidden absolute  opacity-20 md:opacity-90 z-0"
        />
      )}

      {/* <img src="/grid.svg" className='w-full z-0 absolute scale-75 -top-64 opacity-25' /> */}
      {/* <img
        src="/Astro Gradient (1) (8) 2 (1).png"
        className=" w-full h-[112.9vh] overflow-hidden z-0 top-[112.64vh] absolute  opacity-20 sm:opacity-90"
      /> */}

      {/* <div
        className="absolute left-1/2 -translate-x-1/2 pointer-events-none opacity-50 "
        aria-hidden="true"
      >
        <img
          src="/hero-illustration.svg"
          className="max-w-none"
          width="2143"
          height="737"
          alt="Hero Illustration"
        />
      </div> */}
      {/* \\<PlayGroundTryItOutChatbot company='langchain' /> */}
      <Hero />
      <button
        className="secret-toggle fixed z-100 opacity-0"
        onClick={() => {
          setDisplaySearchInDemoComponent(!displaySearchInDemoComponent);
        }}
      >
        invisible
      </button>
      {/* {displaySearchInDemoComponent ? "true" : "false"} */}
      {MendableInPlace && (
        <section
          className={`${
            isLoaded ? 'md:flex hidden' : 'hidden'
          } relative overflow-hidden mb-10 animate-slow-scale  delay-[1000] transition-all `}
        >
          <div
            style={{ padding: '1.5px' }}
            className={`${!scrollToView ? 'loading-box2' : ''}  ${
              true ? 'flex' : 'hidden'
            } overflow-hidden mx-auto w-fit  relative flex-col items-center justify-center rounded-2xl`}
          >
            <span className="spananim"></span>

            <div
              onKeyDown={() => setScrollToView(true)}
              onClick={() => setScrollToView(true)}
              className="relative h-full z-10 min-w-[800px] min-h-[600px] max-w-[800px]   "
            >
              <MendableInPlace
                anon_key="88481495-0e21-49cc-96e5-c1783deb012b"
                showSimpleSearch={displaySearchInDemoComponent}
                style={{
                  accentColor: '#8559F4',
                  darkMode: true,
                  backgroundColor: '#030712'
                }}
                messageSettings={{
                  prettySources: true,
                  persistMessagesBetweenSessions: true,
                  scrollToView: scrollToView,
                  openInNewTabForOutsideSources: true,
                  sourcesFirst: true,
                  sourcesDisplay: 'long'
                }}
                _isFullyLoaded={(b) => {
                  if (b) {
                    setIsLoaded(true);
                  }
                }}
                onMessageForTracking={(q, a, s, c) => {
                  // if it is the first or second message, send a slack message
                  // check the localstorage to see if the length is 1
                  const chatHistory = JSON.parse(
                    localStorage.getItem('mendableBotChatHistorymendable') ||
                      '{}'
                  );
                }}
                onRateClicked={(isLiked, question, answer, sources) => {
                  if (!isLiked) {
                    // Check if the rating is bad
                    let q =
                      question.length > 35
                        ? question.substring(0, 35) + '...'
                        : question;
                    let a =
                      answer.length > 35
                        ? answer.substring(0, 35) + '...'
                        : answer;
                    let s = sources.join(', ');

                    // curl command to send a message, adjusted to include the '@channel' notification
                    sendSlackMessage(
                      `<!channel> This question was rated as *bad*!\n\n*Question:* ${question} \n\n*Answer:* ${answer} \n\n*Sources:* ${s}`
                    );
                  }
                }}
              />

              {/* <MendableSearchBar
                anon_key="88481495-0e21-49cc-96e5-c1783deb012b"
                showSimpleSearch={true}
                messageSettings={{
                  prettySources: true,
                  persistMessagesBetweenSessions: true,
                  scrollToView: scrollToView,
                  openInNewTabForOutsideSources: true,
                }} /> */}
            </div>
          </div>
        </section>
      )}
      {!isLoaded && (
        <div
          className={`invisible z-[1000] lg:visible flex mx-auto flex-col items-center justify-center min-w-[800px] min-h-[600px]`}
        >
          <AnimatedLogoSVG />

          <p className="mt-8">Loading...</p>
        </div>
      )}
      <div
        className={`p-8 flex md:hidden mx-auto flex-col items-center justify-center z-[100] `}
      >
        <img
          src="/heroloading.png"
          alt="Mendable loading placeholder image"
          className="bg-black  z-[100] rounded-xl border border-primary/50"
        />
      </div>
      {/* <CompaniesCarousel /> */}
      <div className="mt-20">
        {/* <CompaniesCarousel /> */}
        <CompaniesGrid />
      </div>

      {/* <PressLogos /> */}
      {/* <Clients /> */}
      {/* <div className='w-64 mx-auto z-10 relative'>
    <MendableSearchBar anon_key='' />
    </div> */}

      <div className="relative z-10">
        <Features2 />
      </div>

      <SecurityComponent />

      {/* <CompaniesCarousel /> */}

      {/* <DataIngestion /> */}
      {/* <Integrations /> */}
      <Features4 />

      <Features5_new />
      <ContinuousTraining />
      <ToolsHero />

      <ChooseYourComponent />
      <Features6 />
      <CustomerTestimonials />

      <div className="relative z-10">
        <EnterpriseReady />
      </div>

      <Features7 />
      {/* <InstallSnippet /> 
      <InsightsFeatures />*/}
      <SenjaTweets className="mt-20" />
      {/*<Testimonials />*/}

      {/* <Features1 /> */}
      {/* <Features3 /> */}
      <CTASection />
      <Faq />
      {/* <MendableFloatingButton
        icon={<MdChatBubble size={40} />}
        anon_key="88481495-0e21-49cc-96e5-c1783deb012b"
        floatingButtonStyle={{ backgroundColor: '#222222', color: '#ffffff' }}
        messageSettings={{
          prettySources: true
        }}
      /> */}
      {/* <IssuesSolved counts={count} /> */}
    </div>
  );
}
