import React, { Fragment } from 'react';
import Spline from '@splinetool/react-spline';

export default function ContinuousTraining() {
  const [componentType, setComponentType] = React.useState('search');

  return (
    <section className="relative">
      {/* Bg gradient */}
      <div
        className="absolute top-0 left-0 right-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Bg illustration */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-5xl  mx-auto text-center pb-8 md:pb-12">
            <div>
              <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-mendable-500 to-mendable-200 pb-3">
                Teach Model
              </div>
            </div>
            <h2 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-gray-200/60 via-gray-200 to-gray-200/60 pb-4">
              Continuous Training
            </h2>
            <p className="text-lg text-gray-400 max-w-xl self-center mx-auto">
            Coach the model by correcting the wrong responses, keeping your chat applications always up to date
            </p>
          </div>
          {/* Section content */}
          <div className="max-w-6xl mx-auto relative ">
            <div className="max-w-3xl mx-auto w-full ">
              <div className="relative hidden md:flex">
                {/* <img src="/Frame 597 (3).png" alt="Mendable component" /> */}
                <Spline scene="https://prod.spline.design/Xv97ZVmWjy9zUJlC/scene.splinecode" />
                {/* <div className="absolute w-full h-64 bottom-0 bg-gradient-to-b  from-transparent via-transparent to-black" /> */}
              </div>

              <div className="hidden sm:flex absolute -bottom-8 -right-0">
                {/* <CodeDisplaySimple code={componentTypes[componentType].code} /> */}
              </div>
            </div>

            {/* <div
                className="max-w-3xl flex items-center justify-between gap-4 mt-4"
                data-aos="zoom-out"
              >
                <div className="">
                <CodeDisplaySimple code={defaultCode} />
                </div>

                <div className="">
                  <CodeDisplaySimple code={code2} />
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
