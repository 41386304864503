import { Button } from '@/components/app/ui/button';
import Highlighter, { HighlighterItem } from '@/components/v2/Highlighter';
import router from 'next/router';
import React, { useEffect, useRef } from 'react';
import { CgArrowUpR } from 'react-icons/cg';
import { FaReact } from 'react-icons/fa';
import createGlobe from 'cobe';

interface Props {
  title: string;
  description?: string | React.ReactNode;
  url: string;
  image?: any;
  IconComponent?: typeof FaReact;
  blurColor?: string;
  className?: string;
}

export default function UseCaseCard({
  title,
  description,
  IconComponent = FaReact,
  url,
  image,
  className,
  blurColor = '#8559F4'
}: Props) {
  return (
    <div className={className}>
      <Highlighter className="group">
        <HighlighterItem>
          <div className="relative h-full bg-gray-950 rounded-[inherit] z-20 overflow-hidden ">
            <div className="w-full h-full flex flex-col md:flex-row md:items-center md:justify-between">
              {/* Blurred shape */}
              <div
                className="  absolute flex items-center justify-center bottom-0 translate-y-3/4 left-1/2 -translate-x-1/2 pointer-events-none -z-10 h-full aspect-square"
                aria-hidden="true"
              >
                <div
                  style={{
                    backgroundColor: blurColor
                  }}
                  className="absolute inset-0 translate-z-0  rounded-full blur-[50px] opacity-70"
                />
                <div className="absolute w-1/4 h-1/4 translate-z-0 bg-primary/25 rounded-full blur-[40px]" />
              </div>
              <div className="relative flex flex-col items-start pt-4 pb-4 pl-6   pr-4 rounded-lg  transition-all  ">
                {/* <div className="absolute -top-px left-20 right-11 h-px bg-gradient-to-r from-primary/0 via-primary/70 to-primary/0" /> */}
                {/* <div className="absolute -bottom-px left-11 right-20 h-px bg-gradient-to-r from-blue-400/0 via-blue-400 to-blue-400/0" /> */}
                <div className="relative z-10 flex h-10 w-10 items-center justify-center rounded-full   ring-gray-900/5 border border-gray-700/50 bg-gray-950 ring-0">
                  {
                    // make a react icon instead of image
                  }

                  <IconComponent className="h-5 w-5 object-contain group-hover:animate-rotate-hover" />
                </div>

                <h2 className="mt-4 text-xl font-semibold text-gray-100 ">
                  <a
                    href={url}
                    rel="noopener noreferrer"
                    className="outline-none decoration-transparent ring-0 border-0"
                  >
                    <span className="absolute -inset-x-4 -inset-y-6 z-20 sm:-inset-x-6 sm:rounded-2xl"></span>
                    <span className="relative text-lg z-10">{title}</span>
                  </a>
                </h2>
                <p className="mt-1 relative z-10 text-sm  text-gray-400 dark:text-gray-400 mb-4">
                  {description}
                </p>
                <div className="w-full flex items-center justify-center">
                  {image}
                </div>
              </div>
            </div>
          </div>
        </HighlighterItem>
      </Highlighter>
    </div>
  );
}
