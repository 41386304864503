import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import CodeDisplay, { CodeDisplaySimple } from '../../CodeDisplay';
import { HeroButton } from '../../Button/HeroButton';
import { Button } from '@/components/app/ui/button';
import CoolCard from '../Cards/CoolCard';

const code2 = `
curl -X POST https://api.mendable.ai/v0/mendableChat
  -H "Content-Type: application/json"
  -d '{
        "api_key": "YOUR_API_KEY",
        "question": "How do I create a new project?",
        "history": [],
        "conversation_id": 12345,
      }'
`;
function Features02() {
  return (
    <section className="relative">
      {/* Bg gradient */}
      <div
        className="absolute top-0 left-0 right-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 h-[10rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Bg illustration */}

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          
          {/* Section content */}
          <div className="max-w-6xl mx-auto relative ">
            <div className='hover: cursor-pointer' onClick={()=>{
              window.open('https://docs.mendable.ai')
            }}>
            <CoolCard
              key={'awd'}
              title={'Ready to use components'}
              url={'https://docs.mendable.ai'}
              description={
                'With our production ready React components and easy to use API you can deploy Mendable your website, mobile app, or any other platform in minutes.'
              }
            />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features02;
